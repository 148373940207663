<template>
    <router-view />
</template>

<style>
@font-face {
    font-family: "Bebas Neue";
    src: url("./assets/fonts/bebasneue-regular-webfont.woff2") format("woff2"),
        url("./assets/fonts/bebasneue-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

#app {
    font-family: "Bebas Neue", Avenir, Helvetica, Arial, sans-serif;
    letter-spacing: 0.35em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
