import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhotoCard = _resolveComponent("PhotoCard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleries, (gallery) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: gallery.path,
        class: "image-card",
        to: `/gallery/${gallery.path}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PhotoCard, {
            imgSrc: gallery.cover,
            title: gallery.title
          }, null, 8, ["imgSrc", "title"])
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}